<template>
  <div class="card p-3" v-if="bankDetails">
    <h6>Bank Details</h6>
    <div class="row">
      <div class="col-4">
        <strong> Account Name</strong>
      </div>
      <div class="col-8">
        {{ bankDetails.account_name }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong> Bank Name</strong>
      </div>
      <div class="col-8">
        {{ bankDetails.bank_name }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong>IBAN</strong>
      </div>
      <div class="col-8">
        {{ bankDetails.iban }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong> Swift Code</strong>
      </div>
      <div class="col-8">
        {{ bankDetails.swift_code }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["bankDetails"],
};
</script>

<style>

</style>
